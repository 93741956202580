import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserLocationState } from '../store/user-location.state';
import { map, Observable } from 'rxjs';

import { UrlBuilder } from '../utils/url-builder';
import { Translatable } from '../models/translation.model';
import { ProfileState } from '../store/profile.state';
import { CompletedTip } from '../services/vroom-tips.service';

enum ApiEndpoint {
  TodayTip = '/vroom_tips/today',
  CompletedTips = '/vroom-tips',
  CompleteTip = '/vroom-tips/:tip_id/completed',
  CategoriesVroom = '/vroom_categories',
  CategoryVroom = '/vroom_categories/:category_id/tips',
}

// This interface has been simplified compared to what the backend return
export interface Tip {
  id: number;
  maxAge: number;
  minAge: number;
  title: Translatable;
  description: Translatable;
  vroomCategory: {
    title: Translatable;
    description: Translatable;
  };
  brainyBackground: Translatable;
  completed: boolean; // this property is constructed in the tips-category.page.ts (merging 2 apis)
}

export interface VroomTipCategory {
  code: string;
  createdAt: Date;
  description: Translatable;
  full_count: string;
  icon: string;
  id: number;
  image: string;
  title: Translatable;
  totalTips: number;
  updatedAt: Date;
  tips: Tip[];
}

export interface VroomTipCategoriesRequestResponse {
  totalCount: number;
  count: number;
  offset: number;
  limit: number;
  vroomCategories: VroomTipCategory[];
}

export interface CompletedTipResponse {
  bookId: string;
  completedAt: Date;
  earnedPoints: number;
  guestUserId: string;
  profileId: string;
  userId: string;
  vroomTipId: number;
}

@Injectable({ providedIn: 'root' })
export class TipsProvider {
  constructor(
    private http: HttpClient,
    private userLocationState: UserLocationState,
    private profileState: ProfileState,
  ) {}

  getTodayTip(): Observable<Tip> {
    const url = new UrlBuilder(ApiEndpoint.TodayTip).setQueryParams({ country: this.userLocationState.userLocation$.value.countryCode }).getUrl();

    return this.http.get<Tip>(environment.booksBaseUrl + url);
  }

  completeTip(id: number): Observable<CompletedTipResponse> {
    const url = new UrlBuilder(ApiEndpoint.CompleteTip)
      .setUrlParams({ tip_id: id })
      .setQueryParams({
        country: this.userLocationState.userLocation$.value.countryCode,
        profileId: this.profileState.currentProfile$.value?.id,
      })
      .getUrl();

    return this.http.post<CompletedTipResponse>(environment.usersBaseUrl + url, {});
  }

  getCompletedTips(): Observable<Array<CompletedTip>> {
    const url = new UrlBuilder(ApiEndpoint.CompletedTips)
      .setQueryParams({
        country: this.userLocationState.userLocation$.value.countryCode,
        profileId: this.profileState.currentProfile$.value?.id,
        limit: 9999,
      })
      .getUrl();

    return this.http.get<{ vroomTips: Array<CompletedTip> }>(environment.usersBaseUrl + url).pipe(map(response => response.vroomTips));
  }

  getCategories(offset: number, limit: number): Observable<VroomTipCategoriesRequestResponse> {
    const url = new UrlBuilder(ApiEndpoint.CategoriesVroom)
      .setQueryParams({
        country: this.userLocationState.userLocation$.value.countryCode,
        offset: offset,
        limit: limit,
      })
      .getUrl();

    return this.http.get<VroomTipCategoriesRequestResponse>(environment.booksBaseUrl + url);
  }

  getCategory(id: number): Observable<VroomTipCategory> {
    const url = new UrlBuilder(ApiEndpoint.CategoryVroom)
      .setUrlParams({
        category_id: id,
      })
      .setQueryParams({
        country: this.userLocationState.userLocation$.value.countryCode,
      })
      .getUrl();

    return this.http.get<VroomTipCategory>(environment.booksBaseUrl + url);
  }
}
