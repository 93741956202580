import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, Subject, takeUntil } from 'rxjs';
import { DefaultProjectState } from '../store/default-project.state';
import { ProfileState } from '../store/profile.state';
import { TipsProvider } from '../providers/tips.provider';

export interface CompletedTip {
  bookId: string;
  completedAt: Date;
  vroomTipId: number;
}

@Injectable({ providedIn: 'root' })
export class VroomTipsService implements OnDestroy {
  shouldDisplayTips$: Observable<boolean> = combineLatest([this.profileState.currentProfile$, this.defaultProjectState.defaultProject$]).pipe(
    map(([currentProfile, defaultProject]) => currentProfile?.isFamilyProfile === true && defaultProject.vroomActivitiesEnabled),
  );

  completedTips$ = new BehaviorSubject<Array<CompletedTip>>([]);
  initialized$ = new BehaviorSubject<boolean>(false);
  private destroyed$ = new Subject<void>();

  constructor(
    private profileState: ProfileState,
    private defaultProjectState: DefaultProjectState,
    private tipsProvider: TipsProvider,
  ) {}

  // Reload completed tips on user change
  // Clear the value on logout
  async initialize(): Promise<void> {
    this.shouldDisplayTips$.pipe(takeUntil(this.destroyed$)).subscribe(async shouldDisplayTips => {
      if (shouldDisplayTips) {
        if (!this.initialized$.value) {
          this.initialized$.next(true);
          this.completedTips$.next(await firstValueFrom(this.tipsProvider.getCompletedTips()));
        }
      } else {
        this.completedTips$.next([]);
        this.initialized$.next(false);
      }
    });

    return Promise.resolve();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
